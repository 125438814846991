<template>
  <div class="publishing-team">
    <h2>Publishing Team</h2>
    <div class="publishing-team__users">
      <div class="user-img">
        <img src="../../assets/images/gladys3.webp" alt="User"/>
      </div>
      <div class="user-details">
        <h4>Gladys Berejiklian</h4>
        <a href="">View Profile</a>
      </div>
    </div>
    <div class="publishing-team__users">
      <div class="user-img">
        <img src="../../assets/images/profile-pic.webp" alt="User"/>
      </div>
      <div class="user-details">
        <h4>Muhammad Ali</h4>
        <a href="">View Profile</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;

.publishing-team {
  padding: 2.2rem 0;

  h2 {
    font-size: 1.9rem;
    font-family: $font-primary-bold;
    margin-bottom: 0.8rem;
  }

  &__users {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;

    .user-img {
      width: 3.8rem;
      margin-right: 1.5rem;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        line-height: 2rem;
      }

      a {
        font-size: 1.2rem;
        color: #011e29;
        font-family: $font-primary-semibold;
      }
    }
  }
}
</style>
