<template>
  <div class="article-author">
    <div class="article-author__profile">
      <div class="profile-img">
        <img src="../../assets/images/mark.jpeg" alt="User"/>
      </div>
      <div class="user-name">
        <p>Published by</p>
        <h2>Mark Manson</h2>
        <p>09 Minutes | Sept 1</p>
      </div>
    </div>
    <div class="article-author__view-profile">
      <a href="#">View Profile</a>
      <a href="#">Become a Patron</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;

.article-author {
  padding: 2.5rem 0;
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.137);

  &__profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .profile-img {
      width: 5rem;
      margin-right: 1.5rem;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }

    .user-name {
      h2 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
      }

      p {
        font-size: 1.1rem;
        font-family: $font-secondary;
      }
    }
  }

  &__view-profile {
    display: flex;
    justify-content: space-between;
    margin-top: 1.7rem;

    a {
      font-size: 1.6rem;
      font-family: $font-primary-semibold;
      color: #011e29;

      @include respond(laptop-small) {
        font-size: 1.3rem;
      }
    }
  }
}
</style>
