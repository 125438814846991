<template>
  <div class="collaborators">
    <h2>Collaborators</h2>
    <div class="collaborators__users">
      <div class="user-img">
        <img src="../../assets/images/Selena-Gomez-Square.webp" alt="User"/>
      </div>
      <div class="user-details">
        <h4>Selena Gomez</h4>
        <a href="">View Profile</a>
      </div>
    </div>
    <div class="collaborators__users">
      <div class="user-img">
        <img src="../../assets/images/4159.webp" alt="User"/>
      </div>
      <div class="user-details">
        <h4>Imran Khan</h4>
        <a href="">View Profile</a>
      </div>
    </div>
    <div class="invite-collaborators">
      <h2>Want to Collaborate?</h2>
      <p>Send collaboration request to the author of this article.</p>
      <button class="btn-primary">I want to Collaborate</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;

.collaborators {
  h2 {
    font-size: 1.9rem;
    font-family: $font-primary-bold;
    margin-bottom: 0.8rem;
  }

  &__users {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;

    .user-img {
      width: 3.8rem;
      margin-right: 1.5rem;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        line-height: 2rem;
      }

      a {
        font-size: 1.2rem;
        color: #011e29;
        font-family: $font-primary-semibold;
      }
    }
  }

  .invite-collaborators {
    background-color: #f6f8ff;
    padding: 2rem;
    width: 100%;
    margin-top: 3rem;
    box-shadow: 0 3px 6px #0000001a;

    h2 {
      font-size: 1.8rem;
      font-family: $font-primary-bold;
      width: 15rem;
      margin-bottom: 0.6rem;
    }

    p {
      font-size: 1.49rem;
      font-family: $font-primary;
    }

    button {
      font-size: 1.6rem;
      font-family: $font-secondary;
      width: 100%;
      height: 3.8rem;
      padding: 0.5rem 0;
      margin-top: 1.2rem;
    }

    button:hover {
      opacity: 0.8;
    }
  }
}
</style>
