<template>
  <div v-if="articleHTML" class="the-article">
    <div class="the-article__title">
      <h2>{{ title }}</h2>
    </div>
    <div class="the-article__social">
      <div class="links">
        <i class="icon icon-account_circle"></i>
        <i class="icon icon-account_circle"></i>
        <i class="icon icon-account_circle"></i>
        <i class="icon icon-bookmark"></i>
      </div>
      <div class="read-time">
        <p>5 mins read</p>
      </div>
    </div>
    <editor-content :editor="editor"/>
  </div>
  <div v-else class="no-preview">
    No preview available
  </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Superscript from "@tiptap/extension-superscript";
import ExternalVideo from "../WorkspaceComponents/Write/Editor/iframe.js";

export default {
  components: {
    EditorContent,
  },
  data() {
    return {
      articleIsEmpty: false,
      articleContent: "",
      editor: null,
    };
  },
  computed: {
    title() {
      return this.$store.getters["editor/getArticleTitle"];
    },
    articleHTML() {
      return this.$store.getters["editor/getArticleBody"].articleBodyHTML;
    },
  },
  mounted() {
    if (this.articleHTML == "<p></p>") {
      this.articleIsEmpty = true;
      this.articleHTML = "";
    }
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TextStyle,
        HorizontalRule.configure({
          HTMLAttributes: {
            class: "article-preview",
          },
        }),
        Image,
        ExternalVideo,
        Link.configure({
          openOnClick: true,
        }),
        Superscript,
      ],
      content: `${this.articleHTML}`,
      editable: false,
    });
  },
};
</script>

<style lang="scss">
@include reset-css;

.the-article {
  padding: 0 2rem !important;
  width: 100%;

  &__title {
    padding: 1.8rem 0;

    h2 {
      font-size: 3rem;
      font-family: $font-primary-bold;
      margin: 0;
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 0.22rem solid #bbbbbb;

    .links {
      .icon {
        font-size: 3rem;
        margin-right: 1.2rem;
        opacity: 0.5;
        cursor: pointer;
      }

      & > .icon:not(:last-child) {
        opacity: 1;
      }
    }

    .read-time {
      p {
        font-size: 1.6rem;
        font-family: $font-primary;
        margin: 0;
      }
    }
  }

  div {
    .ProseMirror {
      padding: 0 1rem;

      p {
        font-size: 1.81rem;
        font-family: $font-primary;
        padding: 2rem 0;
        margin: 0;
      }

      .para {
        font-style: italic;
      }

      img {
        width: 100%;
      }

      .lines {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 1rem 0;

        span {
          height: 1px;
          width: 3.5rem;
          background-color: $color-black;
          margin: 0 0.5rem;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}

.no-preview {
  text-align: center;
  font-size: 1.81rem;
  font-family: $font-primary;
}
</style>
